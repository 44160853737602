<template>
  <div class="topbar">
      <div class="left">
        <a href="/index" class="logo">
          <img :src="staticUrl+'logo.png'" alt>
        </a>
        <a :class="{ active: 'index'=== currentActive }" @click="addClass('index')">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;首頁</a>
        <a :class="{ active: 'game'=== currentActive }" @click="addClass('game')">遊戲介紹</a>
        <a :class="{ active: 'about'=== currentActive }" @click="addClass('about')">關於我們</a>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'topBar',
  data () {
    return {
      currentActive: 'index',
      queryParam: {}
    }
  },
  computed:{
    ...mapGetters(['staticUrl']),
  },
  created () {
    var query = window.location.pathname
    if (query.length > 0) {
      var vars = query.split('/')
      if (vars[1].length === 0) {
        this.currentActive = 'index'
      }else{
        this.currentActive = vars[1]
      }
    }
  },
  methods: {
    addClass (index) {
      this.currentActive = index
      this.$router.push({ name: index, query: this.queryParam })
    }
  }
}
</script>

<style lang="scss">

</style>
