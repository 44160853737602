<template>
  <div class="dialog_wrap" :class="isMobile?'pop_mobile':'pop_pc'" @click="closeDialog">
  <div class="award-dialog prop" @click.stop="noTouch">
    <div class="dialog-close" @click="closeDialog"></div>
    <div class="award-content">
      <div class="award-title"><div class="award-prop-txt"></div></div>
      <div class="award-props">
            <div class="award-prop-item" v-for="(item,index) in propList[propId]" v-bind:key="'prop-'+index">
              <div class="award-prop-icon" :class="item.icon"></div>
              <div class="award-prop-name">{{ item.name }}</div>
            </div>
      </div>
      <div class="award-prop-btns"><div class="award-prop-ok" @click="closeDialog"></div></div>
      
    </div>
  </div>
</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'AwardProp',
  props: {
    isShow: {
      type: Boolean,
      default: true
    },
    propId: {
      type: String,
      default: '2'
    }
  },
  computed: mapState({
    isMobile: (state) => state.act.isMobile,
  }),
  data () {
    return {
      list: [],
      propList: {
        '2': [
          { icon: 'prop-5', name: '尋仙令*1' },
          { icon: 'prop-4', name: '金幣*3888' },
          { icon: 'prop-6', name: '召募令*5' }
        ],
        '3': [
          { icon: 'prop-4', name: '金幣*3888' },
          { icon: 'prop-5', name: '尋仙令*1' },
          { icon: 'prop-6', name: '召募令*5' }
        ],
        '4': [
          { icon: 'prop-2', name: '綁玉*66' },
          { icon: 'prop-5', name: '尋仙令*5' },
          { icon: 'prop-7', name: '進階丹*288' }
        ],
        '5': [
          { icon: 'prop-2', name: '綁玉*58' },
          { icon: 'prop-7', name: '進階丹*88' },
          { icon: 'prop-8', name: '灵草粽*2' }
        ]
      }
    }
  },
  mounted () {
    document.getElementsByTagName('body')[0].classList.add('noscroll');
  },
  beforeDestroy () {
    document.getElementsByTagName('body')[0].classList.remove('noscroll');
  },
  methods: {
    noTouch () {

    },
    closeDialog () {
      if (this.$route.name === 'reservation') {
        this.$emit('onClose', true)
      } else {
        this.$router.push({ name: 'reservation' })
      }
    }
  }
}
</script>

<style lang="scss">

</style>
